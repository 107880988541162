/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from './layout';
import ItemTags from './item-tags';
import SEO from './seo';
import useSiteMetadata from '../hooks/use-site-metadata';
import TwitterShare from './twitter-share';
import ThankYou from './thank-you';
import ScrollToLocationHash from './scroll-to-location-hash';

type PostProps = {
  data: {
    post: {
      slug: string;
      title: string;
      date: string;
      tags?: {
        name: string;
        slug: string;
      }[];
      description?: string;
      body: string;
      excerpt: string;
      timeToRead?: number;
      banner?: {
        childImageSharp: {
          resize: {
            src: string;
          };
        };
      };
    };
  };
};

const px = [`32px`, `16px`, `8px`, `4px`];
const shadow = px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`);

const Post = ({ data: { post } }: PostProps) => {
  const { siteUrl } = useSiteMetadata();
  const url = siteUrl + post.slug;

  return (
    <Layout>
      <ScrollToLocationHash />
      <SEO
        title={post.title}
        description={post.description ? post.description : post.excerpt}
        image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
        pathname={post.slug}
      />
      <Heading variant="styles.h2">{post.title}</Heading>
      <p sx={{ color: `secondary`, mt: 3, a: { color: `secondary` }, fontSize: [1, 1, 2] }}>
        <time>{post.date}</time>
        {post.tags && (
          <React.Fragment>
            {` — `}
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
        {post.timeToRead && ` — `}
        {post.timeToRead && <span>{post.timeToRead} min read</span>}
      </p>
      <section
        sx={{
          my: 5,
          '.gatsby-resp-image-wrapper': { my: [4, 4, 5], boxShadow: shadow.join(`, `) }
        }}
      >
        <MDXRenderer>{post.body}</MDXRenderer>
        <ThankYou />
      </section>
      <TwitterShare url={url} title={post.title} />
    </Layout>
  );
};

export default Post;
