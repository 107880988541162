/** @jsx jsx */
import { jsx, Button, Flex } from 'theme-ui';

// Center opened popup window - https://stackoverflow.com/a/32261263/2347675
const popupWindow = (url: string, title: string, window: Window, width: number, height: number) => {
  const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;

  return window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
  );
};

interface Props {
  title: string;
  url: string;
}

const TwitterShare: React.FC<Props> = ({ title, url }) => {
  const handleShareOnTwitterClick = () => {
    popupWindow(
      `https://twitter.com/intent/tweet?text="${title}" via @BelevMartin&url=${url}`,
      'Share this post on Twitter',
      window,
      600,
      400
    );
  };

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        mb: 5
      }}
    >
      <Button
        sx={{
          padding: 3,
          fontSize: 2
        }}
        onClick={handleShareOnTwitterClick}
      >
        ✨ Share on Twitter ✨
      </Button>
    </Flex>
  );
};

export default TwitterShare;
