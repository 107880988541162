/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';

const ThankYou = () => (
  <section>
    <Styled.hr />
    <Styled.p>
      Thank you for reading this to the end 🙌 . If you enjoyed it and learned something new,
      support me by clicking the share button below to reach more people and/or give me a follow on{' '}
      <Styled.a
        sx={{ variant: 'links.underlined' }}
        href="https://twitter.com/BelevMartin"
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </Styled.a>{' '}
      where we can catch up. I am sharing some other tips, articles, and things I learn there.
      <br />
      If you didn't like the article or you have an idea for improvement, please reach out to me on
      Twitter and drop me a DM with feedback so I can improve and provide better content in the
      future 💪.
    </Styled.p>
  </section>
);

export default ThankYou;
